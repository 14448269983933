// Shorten a string to less than maxLen characters without truncating words.
export function shorten(str, maxLen, separator = ' ') {
  if (str.length <= maxLen) return str;
  const shortenStr = str.substr(0, str.lastIndexOf(separator, maxLen));
  return `${shortenStr}...`;
}

export function encodeQueryData(data) {
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}

export function apiHeader(state, contentType = 'application/json') {
  const token = state.auth.token;
  const header = {};
  if (contentType) header["Content-Type"] = contentType;
  if (token) header["Authorization"] = `Token ${token}`;
  return header;
}
