// User
export const AUTH_API = '/rest-auth/user/';
export const LOGIN_API = '/rest-auth/login/';
export const REGISTER_API = '/rest-auth/register/';
export const LOGOUT_API = '/rest-auth/logout/';
export const USERS_API = '/api/cms/users/';

// Page api
export const PAGES_API = '/api/cms/pages/';
export const SECTIONS_API = '/api/cms/sections/';

// Company API
export const MAESTROS_API = '/api/cms/company/maestros/';
export const TERATORIANS_API = '/api/cms/company/teratorians/';
export const ACHIEVEMENTS_API = '/api/cms/company/achievements/';
export const CLIENT_API = '/api/cms/company/clients/';

// Service API
export const SERVICES_API = '/api/cms/service/services/';
export const SERVICE_LISTS_API = '/api/cms/service/lists/';

// Work API
export const WORKS_API = '/api/cms/work/works/';
export const WORK_CONTENTS_API = '/api/cms/work/contents/';
export const WORK_PLATFORMS_API = '/api/cms/work/platforms/';
export const WORK_DELIVERABLES_API = '/api/cms/work/deliverables/';

// Testimonial API
export const TESTIMONIALS_API = '/api/cms/work/testimonials/';

// Blog API
export const BLOG_POSTS_API = '/api/cms/blog/posts/';
export const POST_CATEGORIES_API = '/api/cms/blog/categories/';
export const POST_TAGS_API = '/api/cms/blog/tags/';

// Career API
export const CAREERS_API = '/api/cms/career/careers/';
export const CAREER_REQUIREMENTS_API = '/api/cms/career/requirements/';
export const CAREER_CATEGORIES_API = '/api/cms/career/categories/';

// Image API
export const IMAGE_API = '/api/cms/images/';
export const IMAGE_COLLECTION_API = '/api/cms/collections/';
