import React, {Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {routes} from './config/urls';

import {Provider as AlertProvider} from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import {Provider} from 'react-redux';
import RouteWithSubRoutes from './components/common/RouteWithSubRoutes';
import axios from 'axios';
import store from './stores/store';
import PageLoading from './components/common/PageLoading';
import {loadUser} from './stores/auth/authAction';

const alertOptions = {
  timeout: 5000,
  position: 'top center'
};

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Router basename="/cms">
          <Suspense fallback={<PageLoading/>}>
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Suspense>
        </Router>
      </AlertProvider>
    </Provider>
  );
};

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export default App;
