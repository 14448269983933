import {Route} from 'react-router-dom';

import PropTypes from 'prop-types';
import React from 'react';

function RouteWithSubRoutes({
                              component: Component,
                              path,
                              exact,
                              isPrivate,
                              routes,
                              ...rest
                            }) {
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={({location, ...props}) => {
        return <Component {...props} routes={routes}/>;

        // if (auth.isLoading) {
        //   return <PageLoading/>;
        // } else if (!auth.isAuthenticated && isPrivate) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: '/login',
        //         state: {from: location}
        //       }}
        //     />
        //   );
        // } else {
        //   return <Component {...props} routes={routes}/>;
        // }
      }}
    />
  );
}

RouteWithSubRoutes.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isPrivate: PropTypes.bool,
  routes: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default RouteWithSubRoutes;
