import {createStore, applyMiddleware, compose} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import rootEpic from './rootEpic';
import {BehaviorSubject} from "rxjs";
import {switchMap} from "rxjs/operators";

const epicMiddleware = createEpicMiddleware();
const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  development ? composeEnhancers(applyMiddleware(epicMiddleware)) : applyMiddleware(epicMiddleware)
);

const epic$ = new BehaviorSubject(rootEpic);
// Every time a new epic is given to epic$ it
// will unsubscribe from the previous one then
// call and subscribe to the new one because of
// how switchMap works
const hotReloadingEpic = (...args) =>
  epic$.pipe(
    switchMap(epic => epic(...args))
  );

epicMiddleware.run(hotReloadingEpic);

// Hot Module Replacement
if (module.hot) {
  module.hot.accept('./rootEpic', () => {
    const nextRootEpic = require('./rootEpic').default;
    epic$.next(nextRootEpic);
  });
}

export default store;
