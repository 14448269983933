import React, {Fragment, lazy} from 'react';
import {Route} from 'react-router-dom';

const Login = lazy(() => import('../components/auth/Login'));
const Dashboard = lazy(() => import('../templates/Dashboard'));
const Page = lazy(() => import('../components/pages'));
const Blog = lazy(() => import('../components/blog'));
const Image = lazy(() => import('../components/image'));
const Career = lazy(() => import('../components/career'));
const Client = lazy(() => import('../components/client'));
const Testimonial = lazy(() => import('../components/testimonial'));
const Work = lazy(() => import('../components/work'));
const Service = lazy(() => import('../components/service'));
const Company = lazy(() => import('../components/company'));
const Employee = lazy(() => import('../components/company/employee'));
const Achievement = lazy(() => import('../components/company/achievement'));
const Error = lazy(() => import('../components/errors/Error'));


export const routes = [
  {
    name: 'login',
    path: '/login',
    exact: true,
    component: Login
  },
  {
    name: 'notFound',
    path: '/404',
    exact: true,
    component: Error
  },
  {
    name: 'home',
    path: '/',
    component: Dashboard,
    isPrivate: true,
    routes: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Fragment
      },
      {
        name: 'pages',
        path: '/pages',
        component: Page
      },
      {
        name: 'blog',
        path: '/blog',
        component: Blog,
      },
      {
        name: 'images',
        path: '/images',
        component: Image,
      },
      {
        name: 'careers',
        path: '/careers',
        component: Career
      },
      {
        name: 'services',
        path: '/services',
        component: Service
      },
      {
        name: 'company',
        path: '/company',
        component: Company
      },
      {
        name: 'employees',
        path: '/employees',
        component: Employee
      },
      {
        name: 'achievements',
        path: '/achievements',
        component: Achievement
      },
      {
        name: 'testimonial',
        path: '/testimonials',
        component: Testimonial
      },
      {
        name: 'clients',
        path: '/clients',
        component: Client
      },
      {
        name: 'works',
        path: '/works',
        component: Work
      }
    ]
  }
];

export const reverseURL = function (name) {
  const route = routes.filter(route => route.name === name);
  return route.length > 0 ? route[0].path : '';
};
